<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">游戏名称：</span>
        <el-input v-model="search.game_name" class="width_200" placeholder="请输入游戏名称" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">游戏分类：</span>
        <el-select v-model="search.type" class="width_150" filterable placeholder="请选择游戏分类" size="medium" clearable>
          <el-option :key="0" label="全部" :value="0"></el-option>
          <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">游戏标签：</span>
        <el-select v-model="search.tag" class="width_100" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="0" label="全部" :value="0"></el-option>
          <el-option :key="1" label="热门" :value="1"></el-option>
          <el-option :key="2" label="推荐" :value="2"></el-option>
          <el-option :key="3" label="最新" :value="3"></el-option>
          <el-option :key="4" label="无" :value="4"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="search.status" class="width_80" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="-1" label="全部" :value="-1"></el-option>
          <el-option :key="0" label="禁用" :value="0"></el-option>
          <el-option :key="1" label="启用" :value="1"></el-option>
        </el-select>
      </div>
      <!-- <div class="search-box">
        <span class="search-box-title">是否热门：</span>
        <el-select v-model="search.hot" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="2" label="全部" :value="2"></el-option>
          <el-option :key="1" label="是" :value="1"></el-option>
          <el-option :key="0" label="否" :value="0"></el-option>
        </el-select>
      </div> -->
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="game_name" label="游戏名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="index_type_str" label="游戏分类" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="icon" label="游戏图标" align="center" :show-overflow-tooltip="true">
        <template width="90" v-slot="scope">
          <ximg :width="80" :height="80" :src="scope.row.icon_link"></ximg>
        </template>
      </el-table-column>
      <el-table-column prop="tag" label="游戏标签" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="disable" label="启用" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch v-model="scope.row.disable" :active-value=1 :inactive-value=0 @change="useGame(scope.row)" />
        </template>
      </el-table-column>
      <!-- <el-table-column prop="hot" label="热门" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch v-model="scope.row.hot" :active-value=1 :inactive-value=0 @change="setHot(scope.row)" />
        </template>
      </el-table-column> -->
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="edit(scope.row)">编辑</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <!-- 编辑 -->
    <el-dialog custom-class="dialog" :title="title" v-model="dialog" width="492px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">游戏名称：</div>
        <el-input v-model="dialogArr.game_name" placeholder="游戏名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">游戏分类：</div>
        <el-select v-model="dialogArr.type" filterable placeholder="请选择游戏分类" size="medium" multiple @change="changeType" clearable>
          <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">游戏图标：</div>
        <el-upload class="avatar-uploader" accept="image/jpeg,image/png,image/webp,image/gif" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
          <ximg v-if="dialogArr.imgUrl" :src="dialogArr.imgUrl" class="avatar" :enlarge="false" ></ximg>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="note">支持扩展名:.png .jpg .gif</div>
      </div>
      <div class="form_box">
        <div class="form_title">游戏标签：</div>
        <el-select v-model="dialogArr.tag" filterable placeholder="请选择游戏标签" size="medium" clearable>
          <el-option :key="4" label="无" :value="4"></el-option>
          <el-option :key="1" label="热门" :value="1"></el-option>
          <el-option :key="2" label="推荐" :value="2"></el-option>
          <el-option :key="3" label="最新" :value="3"></el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">权重：</div>
        <el-input v-model="dialogArr.weight" placeholder="权重" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateGame">确认编辑</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList,
  getGameType,
  useGame,
  setHot,
  updateGame,
} from "@/api/game/list";
import page from "@/components/page";
import { upload } from "@/api/common/common";
export default {
  name: "gameList",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 20 },
      search: {
        //搜索
        game_name: "", // 游戏名称
        type: "", // 类型ID
        tag: 0, // 标签ID
        hot:2,// 是否热梦
        status: -1, // 状态
      },
      tableData: [], //数据
      typeArr: [], // 分类数组
      dialog: false, // 编辑
      title: "", // 标题
      row: {}, // 选中的数据
      dialogArr: {
        // 编辑数据
        game_name: "", // 游戏名称
        type: "", // 类型ID
        url: "",
        imgUrl: "",
        tag: "", // 标签ID
        weight: "", // 权重
      },
      switch: false, // 开关
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
    this.getGameType();
  },
  methods: {
    //获取数据
    getList() {
      if(this.search.hot != 0 && !this.search.hot){
        this.search.hot = 2;
      }
      let status = this.search.status;
      if(this.search.status === ''){
        status = -1;
      }
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "game_name", val: this.search.game_name || "" },
        { key: "type_id", val: this.search.type || 0 },
        { key: "tag", val: this.search.tag || 0 },
        { key: "status", val: status },
        // { key: "hot", val: this.search.hot},
      ]).then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            for (var i in res.data.list) {
              let row = res.data.list[i];
              let index_type_str = "";
              for (const gtype of row.index_type) {
                if (index_type_str != "") {
                  index_type_str += ",";
                }
                index_type_str += gtype.Name;
              }
              res.data.list[i].index_type_str = index_type_str;
            }
            this.tableData = res.data.list;
            this.switch = true;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取分类数据
    getGameType() {
      getGameType()
        .then((res) => {
          if (res.code == 0) {
            this.typeArr = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 禁用启用
    useGame(row) {
      if (!this.switch) return;
      useGame([
        { key: "id", val: row.id },
        { key: "status", val: row.disable },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.$message({ message: res.data, type: "success" });
            this.getList();
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 开启、关闭热门
    setHot(row) {
      if (!this.switch) return;
      setHot([
        { key: "id", val: row.id },
        { key: "hot", val: row.hot },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.$message({ message: res.data, type: "success" });
            this.getList();
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑前填充数据
    edit(row) {
      this.row = row;
      this.dialog = true;
      this.title = `编辑游戏ID:${row.id}`;
      var ids = [];
      for (const gtype of row.index_type) {
        ids.push(gtype.Type);
      }
      this.dialogArr = {
        game_name: row.game_name,
        type: ids,
        url: row.icon,
        imgUrl: this.$store.getImage(row.icon),
        tag: row.tag_id,
        weight: row.weight,
      };
      console.log(this.dialogArr);
    },
    // 编辑
    updateGame() {
      console.log(this.dialogArr.type);
      updateGame([
        { key: "id", val: this.row.id },
        { key: "game_name", val: this.dialogArr.game_name },
        { key: "type", val: this.dialogArr.type.length == 0 ? 0 : this.dialogArr.type.join(",") },
        { key: "icon", val: this.dialogArr.url },
        { key: "tag", val: this.dialogArr.tag },
        { key: "weight", val: this.dialogArr.weight },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialog = false;
            this.getList();
            this.$message({ message: res.msg, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //上传图片
    upload(param) {
      this.$message({ message: "上传中请稍后", type: "success" });
      upload({ file: param.file, name: "game" })
        .then((res) => {
          if (res.code == 0) {
            this.$message({ message: res.msg, type: "success" });
            this.dialogArr.url = res.data.filename;
            this.dialogArr.imgUrl = res.data.path;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 图片上传前校验拦截
    beforeAvatarUpload(file) {
      const type = file.type;
      const isType =  type === "image/jpeg" || type === "image/png" || type === "image/webp" || type === "image/gif"

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传图片格式错误!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过2MB!");
      }
      return isType && isLt2M;
    },
    //放大图片功能
    amplification(url) {
      this.src = url;
      this.srcList = [];
      this.srcList.push(url);
      setTimeout(() => {
        document.getElementsByClassName("bigimg")[0].childNodes[0].click();
      }, 50);
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
/* 图片上传部分 */
.form_box >>> .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.form_box >>> .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.form_box >>> .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.form_box >>> .avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>